import React from 'react';

import { Container, Header, Main, Footer, Cards } from 'components';
import Faqs from 'components/faqs';

const FaqsPage: React.FC = () => {
  return (
    <Container>
      <Header/>
      <section className='faqs-section px-5 md:px-20 py-10 flex flex-col items-center space-y-8'>
        <div className="faqs-section-container px-5 md:px-20 border border-gray-800 py-10 space-y-8">
          <h2 className='text-3xl font-semibold text-center' id='howTo'>Frequently asked questions</h2>
          <Faqs/>
        </div>
      </section>
      <Footer/>
    </Container>
  );
};

export default FaqsPage;
