import React from 'react';

import { Logo } from 'components';
import { FaSquareXTwitter } from "react-icons/fa6";

export const Header: React.FC = () => {
  return (
    <div className='flex justify-between px-8 py-4 items-center z-20'>
      <div className="flex gap-2 items-start cursor-pointer" onClick={() => {window.location.href = '/'}}>
        <img className='h-8' src={'/logo.svg'} alt='xdffi-full-logo'></img>
        <div className="beta-text text-white text-xs font-bold padding-0.5">BETA</div>
      </div>
      <div className="flex gap-16 items-center flex-1 justify-end">
        {/* <FaSquareXTwitter className='w-8 h-8 cursor-pointer' onClick={() => window.open('https://twitter.com/XDFiProtocol')}/> */}
        <div className="menu hidden md:flex gap-16 text-lg font-bold">
          <a href='/#howTo'>How&nbsp;To</a>
          <a href='/faqs'>FAQ</a>
        </div>
        <button
          className="bg-white py-2 px-5 text-black rounded"
          onClick={() => window.open(`https://app-testnet.xdfi.io/`)}
          >
          Launch Beta
        </button>
      </div>
    </div>
  );
};
