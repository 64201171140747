import { Container, Header, Main, Footer, Cards } from 'components';

const TermsOfService = () => {

    const conditions = [
      [
        <span>(1) involve the sending, uploading, distributing or disseminating any unlawful, defamatory, 
        harassing, abusive, fraudulent, obscene, or otherwise objectionable content; </span>,
        <span>
          (2) involve the distribution of any viruses, worms, defects, Trojan horses, corrupted files, hoaxes, 
          or any other items of a destructive or deceptive nature; 
        </span>,
        <span>
          (3) involve the uploading, posting, transmitting or otherwise making available through the 
          PROTOCOL any content that infringes the intellectual proprietary rights of any party;
        </span>,
        <span>(4) involve using the PROTOCOL to violate the legal rights (such as rights of privacy and 
          publicity) of others; </span>,
        <span>(5) involve engaging in, promoting, or encouraging illegal activity (including, without limitation, 
          money laundering);</span>,
        <span>(6) involve interfering with other users’ enjoyment of the PROTOCOL; </span>,
        <span>(7) involve exploiting the PROTOCOL for any unauthorized commercial purpose;</span>,
        <span>(8) involve modifying, adapting, translating, or reverse engineering any portion of the 
        PROTOCOL;</span>,
        <span>(9) involve removing any copyright, trademark or other proprietary rights notices contained in or 
        on the PROTOCOL or any part of it; </span>,
        <span>(10) involve reformatting or framing any portion of the PROTOCOL;</span>,
        <span>(11) involve displaying any content on the PROTOCOL that contains any hate-related or violent 
        content or contains any other material, products or services that violate or encourage conduct that 
        would violate any criminal laws, any other applicable laws, or any third-party rights; </span>,
        <span>(12) involve using any spider, site search/retrieval application, or other device to retrieve or index 
        any portion of the PROTOCOL or the content posted on the PROTOCOL, or to collect information 
        about its users for any unauthorized purpose;</span>,
        <span>(13) involve accessing or using the PROTOCOL for the purpose of creating a product or service 
        that is competitive with any of our products or services;</span>,
        <span>(14) involve abusing, harassing, or threatening another user of the PROTOCOL or any of our 
        authorized representatives, customer service personnel, chat board moderators, or volunteers 
        (including, without limitation, filing support tickets with false information, sending excessive 
        emails or support tickets, obstructing our employees from doing their jobs, refusing to follow the 
        instructions of our employees, or publicly disparaging us by implying favoritism by our employees 
        or otherwise); or </span>,
        <span>(15) involve using any abusive, defamatory, ethnically or racially offensive, harassing, harmful, 
        hateful, obscene, offensive, sexually explicit, threatening or vulgar language when communicating 
        with another user of the PROTOCOL or any of our authorized representatives, customer service 
        personnel, chat board moderators, or volunteers</span>,
        <span>(each, a “Category A Prohibited Activity”); and/or</span>
      ],
      [
        <span>(1)  involve creating user accounts by automated means or under false or fraudulent pretenses; </span>,
        <span>(2)  involve the impersonation of another person (via the use of an email address or otherwise); </span>,
        <span>(3)  involve using, employing, operating, or creating a computer program to simulate the human 
          behavior of a user (“Bots”); </span>,
        <span>(4)  involve using, employing, or operating Bots or other similar forms of automation to engage in 
        any activity or transaction on the PROTOCOL (including, without limitation, purchases of 
        KYCT(s) and/or X-GTs); </span>,
        <span>(5)  involve acquiring a KYCT through inappropriate or illegal means (including, among other 
        things, using a stolen identity, or documentation that you do not have the right to use, or purchasing 
        a wallet containing a KYCT from another party, and then attempting to use a KYCT not associated 
        with your personal identity, while still maintaining ownership or control of your rightfully claimed 
        KYCT for selling, gifting or trading with yourself or others “wash trading”); or </span>,
        <span>(6)  involve the purchasing, selling or facilitating the purchase and sale of any user’s wallet(s) to 
        other users or third parties for cash or cryptocurrency consideration outside of the PROTOCOL; 
        or  </span>,
        <span>(7)  otherwise involve or result in the wrongful seizure or receipt of any KYCT or X-GT or other 
        digital assets (each, a “Category B Prohibited Activity” and, together with Category A Prohibited 
        Activity, the “Prohibited Activities”).</span>
      ]

    ]

    const complaints = [
      [
      <span> an electronic or physical signature of the person authorized to act on behalf of the owner 
      of the copyright or other intellectual property interest;</span>,
      <span> a description of the copyrighted work or other intellectual property that you claim has been 
      infringed; </span>, 
      <span> a description of where the material that you claim is infringing is located on the 
      PROTOCOL, with enough detail that we may find it on the PROTOCOL;</span>,
      <span> your address, telephone number, and email address; </span>,
      <span> a statement by you that you have a good faith belief that the disputed use is not authorized 
      by the copyright or intellectual property owner, its agent, or the law; </span>,
      <span> a statement by you, made under penalty of perjury, that the above information in your 
      Notice is accurate and that you are the copyright or intellectual property owner or 
      authorized to act on the copyright or intellectual property owner’s behalf.</span>
      ],
      [
        <span>your physical or electronic signature; </span>,
        <span>identification of the content that has been removed or to which access has been disabled 
          and the location at which the content appeared before it was removed or disabled; </span>,
        <span>a statement that you have a good faith belief that the content was removed or disabled as a 
          result of mistake or a misidentification of the content; and </span>,
        <span>your name, address, telephone number, and email address, a statement that you consent to 
          the jurisdiction of the federal court located within the Southern District of New York and 
          a statement that you will accept service of process from the person who provided 
          notification of the alleged infringement. </span>
      ]
    ]

    const listItems = (arr: any) => arr.map(item => {
      return(
        <li className='ml-4 mb-2' style={{listStyleType: "1"}}>{item}</li>
      )
    })

    const unorderedListItems = (arr: any) => arr.map(item => {
      return(
        <li style={{listStyleType: 'disc', margin: '1rem 2rem'}}>{item}</li>
      ) 
  });

    return (
        <Container>
      <Header/>
      <div className="terms-of-service p-3 md:p-24">
        <h1 className='text-4xl text-center mb-8'>Terms of Service</h1>
        <p className='mb-4'>
        The XDFi Protocol at <a href='https://www.xdfi.io' target="_blank" className='underline text-blue-400'>https://www.xdfi.io</a> (“XDFi”) is a protocol user interface that provides users 
        with the opportunity to purchase futures contracts for a variety of underlying assets, claim a KYCT 
        token, earn protocol governance tokens (X-GT) and initiate/participate in protocol referral chains. 
        These functions are facilitated by front and back-end systems developed by Sindric Solutions, 
        LLC.  Sindric Solutions, LLC (“Sindric”, “Company”, “we”, or “us”) is making these tools to 
        engage with smart contracts deployed on a public permissionless network to facilitate peer-to-peer 
        transactions, including all related websites and services we host or provide to users (collectively, 
        the “Protocol”).  Before you use the Protocol, however, you will need to agree to these Terms of 
        Service (“Use”) and any terms and conditions incorporated herein by reference (collectively, these 
        “Terms”). 
        <span className='capitalize'>  PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE PROTOCOL.  
          THESE TERMS GOVERN YOUR USE OF THE PROTOCOL FRONT AND BACK END 
          SYSTEMS FOR ENGAGING WITH ON-CHAIN SMART CONTRACTS AND ORACLES ON 
          A PUBLIC PERMISSIONLESS NETWORK, UNLESS WE HAVE EXECUTED A SEPARATE 
          WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE.  WE ARE ONLY WILLING 
          TO MAKE THE PROTOCOL AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE 
          TERMS. <b>BY USING THE PROTOCOL OR ANY PART OF IT, OR BY CLICKING “I 
          ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, 
          YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY 
          ALL OF THESE TERMS.</b> IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A 
          COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE 
          LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN 
          WHICH CASE “YOU” WILL MEAN THAT ENTITY.  IF YOU DO NOT HAVE SUCH 
          AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE 
          UNWILLING TO MAKE THE PROTOCOL AVAILABLE TO YOU.  IF YOU DO NOT 
          AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE PROTOCOL.
        </span>
        </p>
        <p className='capitalize italic font-bold mb-4'>
        PLEASE READ THESE TERMS OF USE CAREFULLY, AS THEY CONTAIN AN 
        AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION 
        REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.  THE 
        AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU 
        SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, 
        AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST 
        SINDRIC SOLUTIONS, LLC ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR 
        CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, 
        (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, 
        INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A 
        JURY OR IN A COURT OF LAW. 
        </p>
        <p className='capitalize font-bold mb-4'>
          ANY PURCHASE OR SALE YOU MAKE, ACCEPT OR FACILITATE OUTSIDE OF 
          THIS PROTOCOL (AS DEFINED BELOW) OF AN FUTURES CONTRACT (AS 
          DEFINED BELOW) WILL BE ENTIRELY AT YOUR RISK. WE DO NOT CONTROL 
          OR ENDORSE PURCHASES OR SALES OF CONTRACTS OUTSIDE OF THIS 
          PROTOCOL. WE EXPRESSLY DENY ANY OBLIGATION TO INDEMNIFY YOU OR 
          HOLD YOU HARMLESS FOR ANY LOSSES YOU MAY INCUR BY TRANSACTING, 
          OR FACILITATING TRANSACTIONS, IN CONTRACTS OUTSIDE OF THIS 
          PROTOCOL. 
        </p>
        <p className='font-bold mb-4'>
          This document contains very important information regarding your rights and obligations, 
          as well as conditions, limitations and exclusions that might apply to you. Please read it 
          carefully.
        </p>
        <p className='mb-4'>
            Any changes to these Terms will be in effect as of the “Last Updated Date” referred to at the top 
            of this page.  We will also notify you, either through the PROTOCOL’s user interface, in an email 
            notification or through other reasonable means.  You should review these Terms before using the 
            PROTOCOL or purchasing any product or using any services that are available through this 
            PROTOCOL.
        </p>
        <p className='mb-4'>
          Your continued use of this PROTOCOL after the “Last Updated Date” will constitute your 
          acceptance of and agreement to such changes.
        </p>
        <p className='mb-4'>
          You may not use this PROTOCOL if you: (i) do not agree to these Terms; (ii) are not of the age 
          of majority in your jurisdiction of residence; or (iii) are prohibited from accessing or using this 
          PROTOCOL or any of this PROTOCOL’s contents, products or services by applicable law.
        </p>

        <h4 className='text-l font-bold mb-4'>1. USE OF THE PROTOCOL; ACCOUNT SET-UP AND SECURITY</h4>
        <p className='mb-4'>(i) <span className='italic'>KYCT and Wallet Set-Up.</span> To most easily use the PROTOCOL, you should first install a web 
          browser (such as the Google Chrome web browser).  You will also need to use a compatible 
          cryptographic electronic wallet or another supported electronic wallet, which will enable you to 
          purchase and store digital assets, tokens, and non-fungible tokens, that you collect, generate, or 
          purchase while engaging with the PROTOCOL, even if not directly FROM the 
          PROTOCOL.  Each digital asset deposited into user wallets is a token asset created on the various 
          public, permissionless Blockchain Networks of Choice (for example but not limited to, the “Flare 
          Network”, “XRPL”, “Xau Hau”, “Polygon”, “Ethereum Network”, etc.).'
          </p>
          <p className='mb-4'>(ii) <span className='italic'>KYCT Registration.</span> In order to comply with the Terms of Service herein, you must provide 
          accurate and complete registration information with our 3rd party KYC provider when you claim 
          your KYCT for use by the PROTOCOL. By claiming the KYCT, you agree to provide accurate, 
          current and complete information about yourself, and to maintain and promptly update your 
          Personally Identifiable Information (PII), as necessary, with the third-party provider and/or Sindric 
          Solutions, LLC.  We reserve the right to revoke KYCT without liability to you if it is found that it 
          was claimed using inaccurate, outdated, or false information. 
          </p>
          <p className='mb-4'>(iii) <span className='italic'> Wallet Security.</span> You are responsible for the security of your electronic wallet for the 
          PROTOCOL and the use therein within the XDFi protocol platform.  If you become aware of any 
          unauthorized use of your wallet as it relates to XDFi, you agree to notify us immediately at either 
          <a href='mailto:hello@sindric.io' className='underline text-blue-400'>hello@sindric.io</a> or <a href='mailto:support@xdfi.io' className='underline text-blue-400'>support@xdfi.io.</a>
          </p>
          <p className='mb-4'>(iv) <span className='italic'>Wallet Transactions.</span> You can use your electronic wallet to purchase, store, and engage in 
          transactions related to the PROTOCOL (e.g. claims, voting, purchases, transfers, etc.), or via one 
          or more cryptocurrencies that we may elect to accept from time to time. Transactions that take 
          place on the PROTOCOL are managed and confirmed via the Blockchain Network of choice.  You 
          understand that your Blockchain Network of choice public address will be made publicly visible 
          whenever you engage in a transaction on the PROTOCOL, however the control of the private keys 
          associated with the wallet(s) will be entirely with the User as the custodian. Non-transferrable 
          assets, such as the KYCT and Governance Tokens (X-GT), may be rescinded with just cause from 
          the user’s wallets using the relevant network standard for administrative functions related to the 
          underlying smart contracts. Revocations of non-transferrable tokens may be disputed with Sindric 
          Solutions, LLC and restorations made after deliberations; however, this is not compelled by any 
          portion of this agreement.
          </p>

          <h4 className='text-l font-bold mb-4'>2. PURCHASING FUTURES CONTRACTS </h4>
          <p className='mb-4'>(i) <span className='italic'> Acquiring Futures Contracts.</span> The PROTOCOL allows you to purchase a futures 
            contract abstraction, reflecting a long or short daily position on the underlying asset 
            price, and allow that position to be matched with an eligible counterparty, peer-to-peer, 
            on the public, permissionless blockchain. The matching function is an off-chain service 
            provided by Sindric Solutions, LLC, however all fund deposits onto on-chain contracts 
            are signed and approved by the user, withdrawn from the user’s electronic wallet upon 
            deposit. You also approve that once a contract is matched the funds associated with 
            that contract are no longer accessible via any external entity (either the user or Sindric 
            Solutions, LLC) until the contract is settled on-chain via a decentralized price feed 
            oracle (for example the FTSO on the Flare Network) and/or the original contract 
            abstraction position is substituted by a new counterparty willing to adjust for intraday 
            position pricing. These statements are true irrespective of Long or Short contract 
            positioning and irrespective of whether the method in which a match was made is a 
            Market or Limit order type.  
          </p>
          <p className='mb-4'>(ii) For the purposes of clarity, contract positions are not purchased from Sindric Solutions, 
            LLC, rather we as the provider of organized matching, based on the eligibility status of 
            the KYCT, allow for peers to match with a counterparty on a globally decentralized 
            ledger. Funds are deposited in an on-chain smart contract without an intermediary or 
            broker and are custodied only by the cryptography of the deposit smart contract 
            deployed to the underlying network. Sindric Solutions, LLC makes no claims as to the 
            security of the underlying network consensus nor guarantees against Sybil attacks or 
            consensus / liveliness failures of the network itself.
          </p>

          <h4 className='text-l font-bold mb-4'>3.  PAYMENT, GAS FEES, AND TAXES</h4>
          <p className='mb-4'>(i) <span className='italic'>  Financial Transactions on the PROTOCOL.</span> As a result of the XDFi Protocol being 100% non
            custodial in nature, any deposits or financial transactions that you engage in via the PROTOCOL 
            will be conducted solely through the smart contract logic associated with the underlying public, 
            permissionless network, wherein final smart contract settlement may involve gas fees, taxes or 
            rents on the associated Blockchain Network(s) of Choice.  We have no control over these on-chain 
            fees or transactions, nor do we have the ability to reverse any payments or transactions after final 
            settlement. This applies to PROTOCOL and referral chain fees as well as final position settlement 
            disbursement as a result of the FTSO price signal. We have no liability to you or to any third party 
            for any claims or damages that may arise as a result of any fees or transactions that you engage in 
            via the PROTOCOL, or any other payment or transactions that you conduct via the Blockchain 
            Network of choice.  <b>We do not provide refunds for any fees or positions that you might make 
            on or through the PROTOCOL – whether for XDFi Protocol futures contracts, gas, or 
            anything else.</b>
          </p>
          <p className='mb-4'>(ii) <span className='italic'> Gas Fees.</span> Every transaction on the Blockchain Network of choice requires the payment of a 
            transaction fee (each, a “Gas Fee”).  The Gas Fees fund the network of computers that run the 
            decentralized Blockchain Network of choice.  This means that you will need to pay a Gas Fee for 
            each transaction that you instigate via the PROTOCOL.  Except as otherwise expressly set forth 
            in these Terms, you will be solely responsible to pay any Gas Fee for any transaction that you 
            instigate via the PROTOCOL. 
          </p>
          <p className='mb-4'>(iii) <span className='italic'> Responsibility for Taxes.</span> You will be solely responsible to pay any and all sales, use, value
            added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter 
            claimed or imposed by any governmental authority (collectively, the “Taxes”) associated with 
            your use of the PROTOCOL.  Except for income taxes levied on us, you: (a) will pay or reimburse 
            us for all national, federal, state, local or other taxes and assessments of any jurisdiction, including 
            value added taxes and taxes as required by international tax treaties, customs or other import or 
            export taxes, and amounts levied in lieu thereof based on charges set, services performed or 
            payments made hereunder, as are now or hereafter may be imposed under the authority of any 
            national, state, local or any other taxing jurisdiction; and (b) will not be entitled to deduct the 
            amount of any such taxes, duties or assessments from payments (including Gas Fees) made to us 
            pursuant to these Terms.  
          </p>

          <h4 className='text-l font-bold mb-4'>4.  OWNERSHIP, CLAIMS, LICENSE, AND OWNERSHIP RESTRICTIONS </h4>
          <p className='font-bold mb-4'>YOUR CLAIM OF A KYCT AND/OR GOVERNANCE TOKEN (X-GT) WILL ONLY BE 
            RECOGNIZED BY US IF YOU HAVE RIGHTFULLY ACQUIRED SUCH ASSETS 
            FROM A LEGITIMATE PROCESS AND NOT THROUGH ANY OF THE CATEGORY 
            B PROHIBITED ACTIVITIES (AS DEFINED BELOW).
          </p>
          <p className='mb-4'>
            For the purposes of this Section 4, the following capitalized terms will have the following 
            meanings:
          </p>
          <p className='mb-4'>“Claim” means, with respect to a KYCT and/or X-GT, that you have otherwise rightfully acquired 
            from a legitimate source (and not through any of the Category B Prohibited Activities (as defined 
            below)), where proof of such claim is recorded on the Blockchain Network of choice and 
            associated with your user wallet and as correlated with an internal Sindric Solutions, LLC 
            database. 
          </p>
          <p className='mb-4'>“Third Party IP” means any third-party patent rights (including, without limitation, patent 
            applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other 
            intellectual property rights recognized in any country or jurisdiction in the world.
          </p>

          <p className='mb-4'>(i) <span className='italic'> Ownership of KYCT and/or X-GT.</span> Because each KYCT and/or X-GT is a smart contract 
            derived token asset on the Blockchain Network of choice, when you claim the KYCT and/or X
            GT in accordance with these Terms (and not through any of the Category B Prohibited Activities), 
            you possess, but not own, the underlying KYCT / X-GT partially. Transfer is prohibited via 
            administrative function, by Sindric Solutions, LLC, over the underlying smart contract.  This 
            means that you do not have the right to swap your KYCT and/or X-GTs, sell, or give it away and 
            is/are bound to the wallet being used to engage with the PROTOCOL. Ownership of the KYCT 
            and/or X-GTs is mediated entirely by the XDFi Protocol that acts as the administrator of the 
            associated smart contract on the Blockchain Network of choice. <b>Except as otherwise permitted 
            by these Terms in cases where we determine that the KYCT, and/or X-GTs, has / have not 
            been rightfully acquired from a legitimate source (including, without limitation, through any 
            of the Category B Prohibited Activities), at no point will we seize, freeze, or otherwise modify 
            the ownership of any KYCT unless the Terms establishing the legitimate basis for the KYCT 
            and/or X-GTs are revoked.</b>
          </p>
          <p className='mb-4'>(ii) <span className='italic'> We Own the PROTOCOL.</span> You acknowledge and agree that we own all legal right, title and 
            interest in and to all other elements of the PROTOCOL, and all intellectual property rights therein 
            (including, without limitation, designs, systems, methods, information, computer code, software, 
            services, “look and feel”, organization, compilation of the content, code, data, and all other 
            elements of the PROTOCOL (collectively, the “PROTOCOL Materials”)), with the exception of 
            smart contracts deployed to the Block Network of choice.  You acknowledge that the PROTOCOL 
            Materials are protected by copyright, trade dress, patent, and trademark laws, international 
            conventions, other relevant intellectual property and proprietary rights, and applicable laws.  All 
            PROTOCOL Materials are the copyrighted property of us or our licensors, and all trademarks, 
            service marks, and trade names associated with the PROTOCOL or otherwise contained in the 
            PROTOCOL Materials are proprietary to us or our licensors. We do not own nor custody any user 
            digital assets or tokens utilized for the purchase of futures contracts nor do we control the processing of smart contracts deployed to a public, permissionless network for the purpose of 
            settling matched futures contracts. From time to time, Sindric Solutions, LLC may choose to 
            reorient the front and back-end infrastructure of the PROTOCOL to point to updated smart 
            contracts on the network and abandon any use of prior versions. No term or condition is this 
            condition compels Sindric Solutions, LLC to maintain integration with previously deployed 
            contracts. Additionally, users are free to deploy independent software at any time to engage with 
            contracts already, or to be, deployed in the practice of running this PROTOCOL.
          </p>
          <p className='mb-4'>(iii) <span className='italic'> No User License or Ownership of PROTOCOL Materials.</span> Except as expressly set forth herein, 
            your use of the PROTOCOL does not grant you ownership of or any other rights with respect to 
            any content, code, data, or other PROTOCOL Materials that you may access on or through the 
            PROTOCOL.  We reserve all rights in and to the PROTOCOL Materials that are not expressly 
            granted to you in these Terms. 
          </p>
          <p className='mb-4'>(iv) <span className='italic'> Further User Ownership Acknowledgements.</span> For the sake of clarity, you understand and 
            agree: (a) that your claim of a KYCT or X-GT, whether via the PROTOCOL or otherwise, does 
            not give you any rights or licenses in or to the PROTOCOL Materials (including, without 
            limitation, our copyright in and to the associated Art) other than those expressly contained in these 
            Terms; (b) that you do not have the right, except as otherwise set forth in these Terms, to reproduce, 
            distribute, or otherwise commercialize any elements of the PROTOCOL Materials (including, 
            without limitation, any designs, nomenclature, or claims to service) without our prior written 
            consent in each case, which consent we may withhold in our sole and absolute discretion; and (c) 
            that you will not register, or otherwise use or attempt to use any of our trademarks or service marks, 
            or any confusingly similar marks, anywhere in the world without our prior written consent in each 
            case, which consent we may withhold at our sole and absolute discretion. 
          </p>
          <p className='mb-4'>(v) <span className='italic'> User License to Art.</span> Subject to your continued compliance with these Terms, we grant you a 
            worldwide, non-exclusive, non-transferable (except as expressly set forth below), royalty-free 
            license to use, and display the KYCT and/or X-GT tokens, solely for the following purposes: (a) 
            for your own personal, non-commercial use on the PROTOCOL; or (b) to promote the features 
            and use of the PROTOCOL to other community members, provided doing so does not violate any 
            of the terms and conditions set forth herein. 
          </p>
          <p className='mb-4'>(vi) <span className='italic'> Restrictions on Ownership.</span> You agree that you may not, nor permit any third party to do or 
            attempt to do any of the foregoing without our express prior written consent in each case: (a) 
            modify KYCT in any way, including, without limitation, the shapes, designs, drawings, attributes, 
            or color schemes of the token design; (b) use the KYCT to advertise, market, or sell any third party 
            product or service; (c) use the KYCT in connection with images, videos, or other forms of media 
            that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found 
            to constitute hate speech or otherwise infringe upon the rights of others; (d) use the KYCT for your 
            purchase of any financial product or service, except to the limited extent that such use is expressly 
            permitted in these Terms or solely for your use in the PROTOCOL; (e) sell, distribute for 
            commercial gain (including, without limitation, giving away in the hopes of eventual commercial 
            gain), or otherwise commercialize merchandise that includes, contains, or consists of the KYCT 
            in any form; (f) attempt to trademark, copyright, or otherwise acquire additional intellectual 
            property rights in or to the KYCT; or (g) otherwise utilize the KYCT for your or any third party’s 
            commercial benefit.
          </p>
          <p className='mb-4'>(vii) <span className='italic'> User Feedback.</span> You may choose to submit comments, bug reports, ideas or other feedback 
            about the PROTOCOL, including without limitation about how to improve the PROTOCOL 
            (collectively, “Feedback”).  By submitting any Feedback, you agree that we are free to use such 
            Feedback at our discretion and without additional compensation to you, and to disclose such 
            Feedback to third parties (whether on a non-confidential basis, or otherwise).  You hereby grant 
            us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to 
            incorporate and use your Feedback for any purpose.
          </p>

          <h4 className='text-l font-bold mb-4'>5. CONDITIONS OF USE AND PROHIBITED ACTIVITIES</h4>
          <p className='font-bold mb-4'>YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE 
            ACCESSING OR USING THE PROTOCOL, AND FOR ANY CONSEQUENCES 
            THEREOF. YOU AGREE TO USE THE PROTOCOL ONLY FOR PURPOSES THAT 
            ARE LEGAL, PROPER AND IN ACCORDANCE WITH THESE TERMS AND ANY 
            APPLICABLE LAWS OR REGULATIONS.
          </p>
          <p className='mb-4'>(i) <span className='italic'> User Warranties.</span> Without limiting the foregoing, you warrant and agree that your use of the 
            PROTOCOL will not (and will not allow any third party to) :
          </p>
              <p className='mb-4 ml-2'>(a) in any manner: </p>
              <ul className='ml-4'>
                {listItems(conditions[0])}
              </ul>
              <p className='mb-4 ml-2'>(b) in any manner: </p>
              <ul className='ml-4'>
                {listItems(conditions[1])}
              </ul>
          <p className='mb-4'>(ii) <span className='italic'> Effect of Your Breaches.</span> If you engage in any of the Prohibited Activities, we may, at our sole 
            and absolute discretion, without notice or liability to you, and without limiting any of our other 
            rights or remedies at law or in equity, immediately suspend or terminate your access to the 
            protocol, revoke the KYCT, and remove access to history generated from the use of the 
            PROTOCOL. If we delete your KYCT access and use via blacklisting a violating wallet, such 
            deletion will not affect your ownership rights in any digital assets and/or property that you already 
            Own, but you will not receive any compensation for funds locked in an on-chain deposit contract 
            that cannot be withdrawn for lack of a valid KYCT. 
          </p>
          <p className='mb-4 font-bold'>
            NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY 
            BELIEVE THAT YOU ARE ENGAGED IN ANY OF THE CATEGORY B PROHIBITED 
            ACTIVITIES, IN ADDITION TO OUR RIGHT TO IMMEDIATELY SUSPEND OR 
            REVOKE YOUR USER KYCT FROM YOUR WALLET AND/OR DELETE YOUR 
            ACCESS TO THE PROTOCOL VIA BLACKLISTING THE WALLET ADDRESS, WE 
            ALSO RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION, 
            WITHOUT NOTICE OR LIABILITY TO YOU, TO TAKE ANY OR ALL OF THE 
            FOLLOWING ACTIONS: (A) NOTIFY LAW ENFORCEMENT OF SUSPECTED 
            ILLEGAL ACTIVITIES (B) TO IMMEDIATELY VOID ANY KYCT AND/OR X-GTS IN 
            THE USER WALLET. 
          </p>
          <p className='mb-4'>If we are served with a subpoena, restraining order, writ of attachment or execution, levy, 
            garnishment, search warrant, or similar order relating to your account or use of the PROTOCOL 
            (“Legal Action”), we will comply with that Legal Action.  Or, in our discretion, we may freeze or 
            place a hold on your KYCT (although not your wallet), until a final determination has been made 
            by a court or relevant regulatory authority regarding the Legal Action.  We may do these things 
            even if the Legal Action involves less than the amount on which was deposited in an on-chain 
            contract.  In these cases, we will not have any liability to you because we fail to complete your 
            requested transaction, or because in any way the on-chain logic restricted access to your funds for 
            failure to find a valid KYCT in your wallet, or use of the PROTOCOL or any transaction in 
            accordance with the Legal Action.  You may be responsible for any fees or expenses we incur in 
            responding to any Legal Action (including attorneys’ fees and our internal expenses). For the 
            purposes of clarity, Sindric Solutions does not have the ability to seize user funds from either 
            wallets or on-chain deposit contracts at any time and the results of seizure of said digital asset 
            property will be subject to actions taken by Legal Action. 
          </p>
          <p className='mb-4'>
            We may also suspend a wallet from submitting orders for deposit and/or matching positions on the 
            PROTOCOL, or any transaction we facilitate (including governance voting), if we believe, in our 
            sole discretion, that the requested transaction is suspicious or is in violation of law or these 
            Terms.  We may refuse to the status of the wallet interfacing with the PROTOCOL for as long as 
            we deem reasonably necessary to complete an investigation of the activity or source of Legal 
            Action.  Sindric Solutions, LLC will attempt to honor and abide by all lawful orders to the extent 
            technically possible.
          </p> 

          <h4 className='text-l font-bold mb-4'>6. TERMINATION </h4>
          <p className='mb-4'>(i) <span className='italic'> You Terminate.</span> You may terminate these Terms at any time by suspending use of your wallet 
            on the PROTOCOL and discontinuing your access to and use of the PROTOCOL. <b>If you cancel 
            your access, or if these Terms are otherwise terminated for any reason, you will still receive 
            funds that you might make on or through the PROTOCOL for valid contract settlements – 
            even if the use of the PROTOCOL has been suspended.</b> Continued support for ongoing KYCT 
            and X-GT use is not guaranteed by Sindric Solutions, LLC and may mean that access to funds in 
            a deposit contract could be temporarily prohibited by code logic, and not by human action or 
            intervention on our behalf.
          </p>
          <p className='mb-4'>(ii) <span className='italic'> We Terminate.</span> You agree that we, in our sole discretion and for any or no reason, may 
            terminate these Terms and suspend and/or terminate your access to the PROTOCOL without the 
            provision of prior notice, via blacklisting of a wallet address, or suspension of a valid KYCT. You 
            agree that any suspension or termination of your access to the PROTOCOL may be without prior 
            notice, and that we will not be liable to you or to any third party for any such suspension or 
            termination. 
          </p>
          <p className='mb-4'>(iii) <span className='italic'> Other Remedies Available.</span> If we terminate these Terms or suspend or terminate your access 
            to or use of the PROTOCOL due to your breach of these Terms or any suspected fraudulent, 
            abusive, or illegal activity (including, without limitation, if you engage in any of the Prohibited 
            Activities), then termination of these Terms will be in addition to any other remedies we may have 
            at law or in equity. 
          </p>
          <p className='mb-4'>(iv) <span className='italic'> Referral to Governmental Authority.</span> We have the right, without provision of prior notice, to 
            take appropriate legal action, including, without limitation, referral to law enforcement or 
            regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the 
            PROTOCOL. Without limiting the foregoing, we have the right to fully cooperate with any law 
            enforcement authorities or court order requesting or directing us to disclose the information 
            associated with the claiming of a KYCT, and subsequent referrals to third party KYC providers, 
            or other information provided to the PROTOCOL. For clarity, the XDFi Protocol does not collect 
            Personally Identifiable Information from users of any kind, but remands the collection of said data 
            to licensed third party KYC/AML providers. The PROTOCOL does correlate minted KYCTs to 
            unique API identifiers for requests/receipts of information from the 3rd party provider and embeds 
            this information in the token mint on-chain meta-data. 
          </p>
          <p className='mb-4'>(v) <span className='italic'> Effect of Termination.</span> Upon any termination or expiration of these Terms, whether by you or 
            us, you may no longer have access to information that you have posted on the PROTOCOL or that 
            is related to your account, and you acknowledge that we will have no obligation to maintain any 
            such information in our databases or to forward any such information to you or to any third party. 
            Sections 1 and 3 through 17 will survive the termination or expiration of these Terms for any 
            reason.
          </p>
          <p className='mb-4 font-bold'>YOU WAIVE AND HOLD US AND OUR PARENT, SUBSIDIARIES, AFFILIATES AND 
            OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, 
            SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM 
            ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING 
            TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY LAW 
            ENFORCEMENT AUTHORITIES. 
          </p>

          <>
            <h4 className='text-l font-bold mb-4'>7. COPYRIGHT COMPLAINTS </h4>
            <p className='mb-4'>
              Sindric Solutions, LLC respects the intellectual property of others, and we ask our users to do the 
              same.  If you believe that your work has been copied in a way that constitutes copyright 
              infringement, or that your intellectual property rights have been otherwise violated, you should 
              notify Sindric of your infringement claim in accordance with the procedure set forth below.
            </p>
            <p className='mb-4'>Sindric will process and investigate notices of alleged infringement and will take appropriate 
              actions under the Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual 
              property laws with respect to any alleged or actual infringement.  A notification of claimed 
              copyright infringement should be emailed to Sindric Solutions, LLC’s Copyright Agent at 
              <a href='mailto:hello@sindric.io' className='underline text-blue-400'>hello@sindric.io</a> and/or <a href='mailto:support@xdfi.io' className='underline text-blue-400'>support@xdfi.io</a> (Subject line: “DMCA Takedown Request”).  You may 
              also contact us by mail:
            </p>
            <p>Sindric Solutions, LLC </p>
            <p>8 Phillip Dr </p>
            <p>Edison, NJ 08820 </p>
            <p className='mb-4'>USA</p>
            <p className='mb-4'>
              To be effective, the notification must be in writing and contain the following information: 
            </p>
              <ul>
                {unorderedListItems(complaints[0])}
              </ul>
            <p className='mb-4'>
                <span className='italic'>Counter-Notice: </span>If you believe your User Content that was removed (or to which access was 
                disabled) is not infringing, or that you have the authorization from the copyright owner, the 
                copyright owner’s agent, or pursuant to the law, to upload and use the content in your User Content, you may send a written counter-notice containing the following information to the Copyright 
                Agent:
            </p>
              <ul>
                {unorderedListItems(complaints[1])}
              </ul>
            <p className='mb-4'>
              If a counter-notice is received by the Copyright Agent, Sindric Solutions, LLC will send a copy of 
              the counter-notice to the original complaining party informing that person that it may replace the 
              removed content or cease disabling it in 10 business days. Unless the copyright owner files an 
              action seeking a court order against the content provider, member or user, the removed content 
              may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the 
              counter-notice, at our sole discretion.
            </p>
            <p className='mb-4'>
              <span className='italic'>Repeat Infringer Policy: </span>In accordance with the DMCA and other applicable law, Sindric 
              Solutions, LLC has adopted a policy of terminating, in appropriate circumstances and at Sindric's 
              sole discretion, users who are deemed to be repeat infringers.  Sindric may also at its sole discretion 
              limit access to the PROTOCOL and/or terminate the memberships of any users who infringe any 
              intellectual property rights of others, whether or not there is any repeat infringement.
            </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>8. DISCLAIMERS </h4>
          <>
          <p className='mb-4'>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF 
            THE PROTOCOL IS AT YOUR SOLE RISK, AND THAT THE PROTOCOL IS PROVIDED 
            "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER 
            EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO 
            APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE 
            NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES 
            REGARDING THE PROTOCOL AND ANY PART OF IT, INCLUDING THE IMPLIED 
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
            NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT 
            LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR PARENT, SUBSIDIARIES, 
            AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) 
            YOUR ACCESS TO OR USE OF THE PROTOCOL WILL MEET YOUR REQUIREMENTS; 
            (II) YOUR ACCESS TO OR USE OF THE PROTOCOL WILL BE UNINTERRUPTED, 
            TIMELY, SECURE OR FREE FROM ERROR; (III) USAGE DATA PROVIDED THROUGH 
            THE PROTOCOL WILL BE ACCURATE; (IV) THE PROTOCOL OR ANY CONTENT, 
            SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE PROTOCOL ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (V) THAT ANY DATA 
            THAT YOU DISCLOSE WHEN YOU USE THE PROTOCOL WILL BE SECURE. SOME 
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN 
            CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS 
            MAY NOT APPLY TO YOU.
          </p>
          <p className='mb-4'>
            YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND 
            DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY 
            OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR 
            GROSS NEGLIGENCE.
          </p>
          <p className='mb-4'>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR 
            AS THE RESULT OF YOUR USE OF THE PUBLIC PERMISSIONLESS BLOCKCHAIN 
            NETWORK(S) OF CHOICE, OR YOUR ELECTRONIC WALLET, INCLUDING BUT NOT 
            LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR, 
            SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART 
            CONTRACTS OR OTHER TRANSACTIONS; (II) SERVER FAILURE OR DATA LOSS; (III) 
            CORRUPTED WALLET FILES; OR (IV) UNAUTHORIZED ACCESS OR ACTIVITIES BY 
            THIRD PARTIES, INCLUDING, BUT NOT LIMITED TO, THE USE OF VIRUSES, 
            PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE 
            PROTOCOL, THE BLOCKCHAIN NETWORK OF CHOICE, OR ANY ELECTRONIC 
            WALLET.
          </p>
          <p className='mb-4'>
            FUTURES CONTRACTS MATCHED BY THE PROTOCOL ARE INTANGIBLE DIGITAL 
            ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED 
            IN THE BLOCKCHAIN NETWORK OF CHOICE AND ASSOCIATED WITH YOUR USER 
            WALLET. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE 
            DECENTRALIZED LEDGER WITHIN THE BLOCKCHAIN NETWORK OF CHOICE. WE 
            HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES WITH 
            RESPECT TO SMART CONTRACTS AND THEIR SECURITY AND/OR PROPER 
            FUNCTIONALITY. 
          </p>
          <p className='mb-4'>
            WE ARE NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER 
            FEATURES OF THE BLOCKCHAIN NETWORK OF CHOICE, OR ANY ELECTRONIC 
            WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR 
            REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE 
            BLOCKCHAIN SUPPORTING THE BLOCKCHAIN NETWORK OF CHOICE, INCLUDING 
            FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES 
            AS A RESULT. THIS INCLUDES ANY FAILURE OF ORACLE NETWORKS IN THE 
            DELIVERY OF ADEQUATE AND ACCURATE PRICE SIGNALS THAT DETERMINE THE 
            FINAL SETTLEMENT OF THE UNDERLYING MATCHED FUTURES CONTRACTS 
            MATCHED.  
          </p>
          </>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>9. LIMITATION OF LIABILITY </h4>
          <div className='font-bold'>
            <p className='mb-4'>
              YOU UNDERSTAND AND AGREE THAT WE, AND OUR AFFILIATES AND 
              LICENSORS, WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY 
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY 
              DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY 
              THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF 
              PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF 
              GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF 
              PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE 
              OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE 
              POSSIBILITY OF SUCH DAMAGES. 
            </p>
            <p className='mb-4'>
              YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND 
              ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR 
              ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION 
              OF THE PROTOCOL, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR 
              ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF THE AMOUNTS 
              YOU HAVE ACTUALLY AND LAWFULLY PAID US UNDER THESE TERMS IN THE 
              TWO (2) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE.
            </p>
            <p className='mb-4'>YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE PROTOCOL 
              AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON 
              THE REPRESENTATIONS AND WARRANTIES, DISCLAIMERS AND LIMITATIONS 
              OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR 
              ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS 
              OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO 
              PROVIDE THE PROTOCOL TO YOU WITHOUT THESE LIMITATIONS. 
            </p>
            <p className='mb-4'>IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED 
              “DISCLAIMERS” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY 
              AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY.  
              IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE 
              LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION 
              SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE 
              APPLICABLE SECTIONS. 
            </p>
          </div>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>10. ASSUMPTION OF RISK </h4>
          <p className='mb-4'>
            (i) <span className='italic'>Value and Volatility.</span> The prices of digital assets are extremely volatile and subjective and 
            blockchain related assets (contracts or deposits) have no inherent or intrinsic value.  Fluctuations 
            in the price of other digital assets could materially and adversely affect the value of your contract 
            position, which may also be subject to significant price volatility. Each KYCT and/or X-GT has 
            no inherent or intrinsic value.  We cannot guarantee that any Futures Contract that is matched with 
            a valid counterparty will retain or preserve any value (in any form) deposited into on-chain smart 
            contracts, as the value of a position is ultimately subject to market forces outside of the control of 
            the PROTOCOL.  
          </p>
          <p className='mb-4'>
            (ii) <span className='italic'>Tax Calculations.</span> You are solely responsible for determining what, if any, taxes apply to your 
            PROTOCOL-related transactions.  We are not responsible for determining the taxes that apply to 
            your transactions on the PROTOCOL.
          </p>
          <p className='mb-4'>
            (iii) <span className='italic'>Use of Blockchain.</span> The PROTOCOL does not store, send, or receive KYCTs in of itself and 
            all custodial functions are born by the user and their respective cryptographic wallet.  This is 
            because KYCTs, X-GTs, and digital assets used as deposit funds (FLR, FASSETS, etc.) exist only 
            by virtue of the ownership record maintained on the PROTOCOL’s supporting blockchain in the 
            Blockchain Network of choice. Any transfer of digital assets by virtue of executed on-chain smart 
            contracts (e.g. at time of settlement) occurs within the supporting blockchain on the Blockchain 
            Network of choice, and not on the PROTOCOL. Sindric Solutions, LLC and the XDFi Protocol 
            do not act in a custodial capacity at any time for any of the aforementioned assets.  
          </p>
          <p className='mb-4'>
            (iv) <span className='italic'>Inherent Risks with Internet Currency.</span> There are risks associated with using an Internet-based 
            currency, including, but not limited to, the risk of hardware, software and Internet connections, the 
            risk of malicious software introduction, and the risk that third parties may obtain unauthorized 
            access to information stored within your electronic wallet.  You accept and acknowledge that we 
            will not be responsible for any communication failures, disruptions, errors, distortions or delays 
            you may experience when using the Blockchain Network of choice, however caused.
          </p>
          <p className='mb-4'>
            (v) <span className='italic'>Regulatory Uncertainty.</span> The regulatory regime governing blockchain technologies, 
            cryptocurrencies and tokens is uncertain, and new regulations or policies may materially adversely 
            affect the development of the XDFi Protocol, and therefore the potential utility or value of your 
            use of the protocol may vary significantly and without notice.
          </p>
          <p className='mb-4'>
            (vi) <span className='italic'>Software Risks.</span> Upgrades to the Blockchain Network of choice underpinning the smart 
            contract constituting the PROTOCOL interfacing contracts, a hard fork in the Blockchain Network 
            of choice, or a change in how transactions are confirmed on the Blockchain Network of choice 
            may have unintended, adverse effects on all dependent blockchains, including the KYCT, X-GT, 
            and deposit contracts used by the PROTOCOL. 
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>11. INDEMNIFICATION </h4>
          <p className='mb-4'>
            You agree to hold harmless and indemnify us and our affiliates, and us and our affiliates’ officers, 
            agents, employees, advertisers, licensors, suppliers and partners, from and against any claim, 
            liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation 
            cost and attorneys' fees arising out of or in any way related to: (i) your breach of these Terms; (ii) 
            your misuse of the PROTOCOL; or (iii) your violation of applicable laws, rules or regulations in 
            connection with your access to or use of the PROTOCOL. You agree that we will have control of 
            the defense or settlement of any such claims. 
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>12. EXTERNAL SITES </h4>
          <p className='mb-4'>
            The PROTOCOL may include hyperlinks to other websites or resources (collectively, the 
            “External Sites”), which are provided solely as a convenience to our users.  We have no control 
            over any External Sites.  You acknowledge and agree that we are not responsible for the 
            availability of any External Sites, and that we do not endorse any advertising, products or other 
            materials on or made available from or through any External Sites.  Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the 
            availability or unavailability of the External Sites, or as a result of any reliance placed by you upon 
            the completeness, accuracy or existence of any advertising, products or other materials on, or made 
            available from, any External Sites.  
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>13. FORCE MAJEURE</h4>
          <p className='mb-4'>
            (i) <span className='italic'>Force Majeure Events.</span> We will not be liable or responsible to you, nor be deemed to have 
            defaulted under or breached these Terms, for any failure or delay in fulfilling or performing any 
            of these Terms, when and to the extent such failure or delay is caused by or results from the 
            following force majeure events (“Force Majeure Event(s)”): (a) acts of God; (b) flood, fire, 
            earthquake, epidemics, pandemics, including the 2019 novel coronavirus pandemic (COVID-19), 
            tsunami, explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats 
            or acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades 
            in effect on or after the date of this agreement; (f) strikes, labour stoppages or slowdowns or other 
            industrial disturbances; (g) shortage of adequate or suitable Internet connectivity, 
            telecommunication breakdown or shortage of adequate power or electricity; and (h) other similar 
            events beyond our control.
          </p>
          <p className='mb-4'>
            (i) <span className='italic'>Performance During Force Majeure Events.</span> If we suffer a Force Majeure Event, we will use 
            reasonable efforts to promptly notify you of the Force Majeure Event, stating the period of time 
            the occurrence is expected to continue. We will use diligent efforts to end the failure or delay and 
            ensure the effects of such Force Majeure Event are minimized. We will resume the performance 
            of our obligations as soon as reasonably practicable after the removal of the cause. In the event 
            that our failure or delay remains uncured for a period of forty-five (45) consecutive days following 
            written notice given by us under this Section 12, we may thereafter terminate these Terms upon 
            fifteen (15) days' written notice.
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>14. CHANGES TO THE PROTOCOL </h4>
          <p className='mb-4'>
            We are constantly innovating the PROTOCOL to help provide the best possible experience. You 
            acknowledge and agree that the form and nature of the PROTOCOL, and any part of it, may change 
            from time to time without prior notice to you, and that we may add new features and change any 
            part of the PROTOCOL at any time without notice.  
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>15. CHILDREN</h4>
          <p className='mb-4'>
            You affirm that you are over the age of 13.  The PROTOCOL is not intended for children under 
            13.  If you are under the age of 13, you may not use the PROTOCOL.  We do not knowingly 
            collect information from or direct any of our content specifically to children under the age of 13.  If 
            we learn or have reason to suspect that you are a user who is under the age of 13, we will 
            unfortunately have to close your account.  Other countries may have different minimum age limits, 
            and if you are below the minimum age for providing consent for data collection in your country, 
            you may not use the PROTOCOL.    
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>16. PRIVACY POLICY</h4>
          <p className='mb-4'>
            Our Privacy Policy describes the ways we do (or do not) collect, use, store and disclose personal 
            information (including for know-your-customer/KYC checks), and is hereby incorporated by this 
            reference into these Terms. You agree to the collection, use, storage, and disclosure of your data 
            in accordance with our Privacy Policy and by extension agree to the Terms & Conditions of our 
            third-party KYC/AML provider. 
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>17. DISPUTE RESOLUTION; BINDING ARBITRATION </h4>
          <h4 className='text-l font-bold mb-4'>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS. </h4>
          <p className='mb-4'>
            <ol className='ml-4'>
            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}> Agreement to Arbitrate </li>
              <p className='mb-4'>
                This Dispute Resolution by Binding Arbitration section is referred to in these Terms as the 
                “Arbitration Agreement.”  You agree that any and all disputes or claims that have arisen or may 
                arise between you and Sindric Solutions, LLC, whether arising out of or relating to the Terms 
                (including any alleged breach thereof), the PROTOCOL, any KYCTs / X-GTs, and deposited 
                assets, to the extent technically feasible, within the standard of material control, any advertising, 
                any aspect of the relationship or transactions between us, shall be resolved exclusively through 
                final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration 
                Agreement, except that you may assert individual claims in small claims court, if your claims 
                qualify.  Further, this Arbitration Agreement does not preclude you from bringing issues to the 
                attention of federal, state, or local agencies, and such agencies can, if the law allows, seek relief 
                against us on your behalf.  You agree that, by entering into these Terms, you and Sindric Solutions, 
                LLC are each waiving the right to a trial by jury or to participate in a class action.  Your rights will 
                be determined by a neutral arbitrator, not a judge or jury.  The Federal Arbitration Act governs the 
                interpretation and enforcement of this Arbitration Agreement. 
              </p>
            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Prohibition of Class and Representative Actions and Non-Individualized Relief</li>
            <p className='mb-4 font-bold italic'>
              YOU AND SINDRIC SOLUTIONS, LLC, ACTING THROUGH THE XDFI.IO PLATFORM, 
              AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN 
              INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY 
              PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.  UNLESS 
              BOTH YOU AND SINDRIC SOLUTIONS, LLC AGREE OTHERWISE, THE ARBITRATOR 
              MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS 
              AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, 
              REPRESENTATIVE, OR CLASS PROCEEDING.  ALSO, THE ARBITRATOR MAY 
              AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY 
              RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY 
              TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT 
              PARTY’S INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR 
              AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER 
              APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF 
              THIS PROVISION.
            </p>
            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Pre-Arbitration Dispute Resolution</li>
            <p className='mb-4'>
              Sindric Solutions, LLC is always interested in resolving disputes amicably and efficiently, and 
              most customer concerns can be resolved quickly and to the customer’s satisfaction by emailing 
              customer support at <a href='mailto:hello@sindric.io' className='underline text-blue-400'>hello@sindric.io</a> or <a href='mailto:support@xdfi.io' className='underline text-blue-400'>support@xdfi.io.</a>  If such efforts prove unsuccessful, a 
              party who intends to seek arbitration must first send to the other, by certified mail, a written Notice 
              of Dispute (“Notice”).  The Notice to Sindric Solutions, LLC should be sent to Sindric Solutions, 
              LLC, 8 Phillip Dr, Edison, NJ 08820, USA (“Notice Address”).  The Notice must (i) describe the 
              nature and basis of the claim or dispute and (ii) set forth the specific relief sought.  If Sindric 
              Solutions, LLC and you do not resolve the claim within sixty (60) calendar days after the Notice 
              is received, you or Sindric Solutions, LLC may commence an arbitration proceeding.  During the 
              arbitration, the amount of any settlement offer made by Sindric Solutions, LLC or you shall not be 
              disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or 
              Sindric Solutions, LLC is entitled. 
            </p>

            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Arbitration Procedures</li>
            <p className='mb-4'>
            Arbitration will be conducted by a neutral arbitrator in accordance with the American Arbitration 
              Association’s (“AAA”) rules and procedures, including the AAA’s Consumer Arbitration Rules 
              (collectively, the “AAA Rules”), as modified by this Arbitration Agreement.  For information on 
              the AAA, please visit its website, http://www.adr.org.  Information about the AAA Rules and fees 
              for consumer disputes can be found at the AAA’s consumer arbitration page, http://www.adr.org/ 
              as may be updated from time to time.  If there is any inconsistency between any term of the AAA 
              Rules and any term of this Arbitration Agreement, the applicable terms of this Arbitration 
              Agreement will control unless the arbitrator determines that the application of the inconsistent 
              Arbitration Agreement terms would not result in a fundamentally fair arbitration.  The arbitrator 
              must also follow the provisions of these Terms of Use as a court would.  All issues are for the 
              arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and 
              arbitrability of this Arbitration Agreement.  Although arbitration proceedings are usually simpler 
              and more streamlined than trials and other judicial proceedings, the arbitrator can award the same 
              damages and relief on an individual basis that a court can award to an individual under the Terms 
              of Use and applicable law.  Decisions by the arbitrator are enforceable in court and may be 
              overturned by a court only for very limited reasons.
            </p>
            <p className='mb-4'>
              Unless Sindric Solutions, LLC and you agree otherwise, any arbitration hearings will take place 
              in a reasonably convenient location for both parties with due consideration of their ability to travel 
              and other pertinent circumstances.  If the parties are unable to agree on a location, the 
              determination shall be made by AAA.  If your claim is for $10,000 or less, Sindric Solutions, LLC 
              agrees that you may choose whether the arbitration will be conducted solely on the basis of 
              documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as 
              established by the AAA Rules.  If your claim exceeds $10,000, the right to a hearing will be 
              determined by the AAA Rules.  Regardless of the manner in which the arbitration is conducted, 
              the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and 
              conclusions on which the award is based. 
            </p>

            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Costs of Arbitration</li>
            <p className='mb-4'>
            Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”) will 
            be governed by the AAA Rules, unless otherwise provided in this Arbitration Agreement.  If the value of the relief sought is $75,000 or less, at your request, Sindric Solutions, LLC will pay all 
            Arbitration Fees.  If the value of relief sought is more than $75,000 and you are able to demonstrate 
            to the arbitrator that you are economically unable to pay your portion of the Arbitration Fees or if 
            the arbitrator otherwise determines for any reason that you should not be required to pay your 
            portion of the Arbitration Fees, Sindric Solutions, LLC will pay your portion of such fees.  In 
            addition, if you demonstrate to the arbitrator that the costs of arbitration will be prohibitive as 
            compared to the costs of litigation, Sindric Solutions, LLC will pay as much of the Arbitration 
            Fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive.  Any 
            payment of attorneys’ fees will be governed by the AAA Rules.
            </p>

            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Confidentiality </li>
            <p className='mb-4'>
            All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will 
            be strictly confidential for the benefit of all parties. 
            </p>

            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Severability</li>
            <p className='mb-4'>If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other 
            than the subsection (b) titled “Prohibition of Class and Representative Actions and Non
            Individualized Relief” above) is invalid or unenforceable, the parties agree to replace such term or 
            provision with a term or provision that is valid and enforceable and that comes closest to expressing 
            the intention of the invalid or unenforceable term or provision, and this Arbitration Agreement 
            shall be enforceable as so modified.  If a court or the arbitrator decides that any of the provisions 
            of subsection (b) above titled “Prohibition of Class and Representative Actions and Non
            Individualized Relief” are invalid or unenforceable, then the entirety of this Arbitration Agreement 
            shall be null and void, unless such provisions are deemed to be invalid or unenforceable solely 
            with respect to claims for public injunctive relief.  The remainder of the Terms of Use will continue 
            to apply. </p>

            <li className='font-bold mb-2' style={{listStyleType: 'lower-alpha'}}>Future Changes to Arbitration Agreement</li>
            <p className='mb-4'>
            Notwithstanding any provision in these Terms to the contrary, Sindric Solutions, LLC agrees that 
            if it makes any future change to this Arbitration Agreement (other than a change to the Notice 
            Address) while you are a user of the PROTOCOL, you may reject any such change by sending 
            Sindric Solutions, LLC written notice within thirty (30) calendar days of the change to the Notice 
            Address provided above.  By rejecting any future change, you are agreeing that you will arbitrate 
            any dispute between us in accordance with the language of this Arbitration Agreement as of the 
            date you first accepted these Terms (or accepted any subsequent changes to these Terms). 
            </p>
            </ol>
          </p>
          </>

          <>
          <h4 className='text-l font-bold mb-4'>18.  GENERAL </h4>
          <p className='mb-4'>
           (i) <span className='italic'>Entire Agreement.</span> These Terms and our Privacy Policy constitute the entire legal agreement 
            between you and us and will be deemed to be the final and integrated agreement between you and 
            us, and govern your access to and use of the PROTOCOL, and completely replace any prior or 
            contemporaneous agreements between you and us related to your access to or use of the 
            PROTOCOL, whether oral or written. 
          </p>

          <p className='mb-4'>
           (ii) <span className='italic'>Notice for California Users.</span> Under California Civil Code Section 1789.3, users of the 
            PROTOCOL from California are entitled to the following specific consumer rights notice: The 
            Complaint Assistance Unit of the Division of Consumer Services of the California Department of 
            Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, 
            Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210. You may contact 
            us at Sindric Solutions, LLC, Inc., in writing at Sindric Solutions, LLC, 265 Lincoln Highway, 
            Iselin, NJ 08830 or by telephone at (508) 566-1047.
          </p>

          <p className='mb-4'>
            (iii)<span className='italic'>No Third-Party Beneficiaries.</span> These Terms do not and are not intended to confer any of your 
            rights or remedies upon any person or entity other than you. 
          </p>

          <p className='mb-4'>
           (iv) <span className='italic'>Interpretation.</span> The language in these Terms will be interpreted as to its fair meaning, and not 
            strictly for or against any party. 
          </p>

          <p className='mb-4'>
            (v)<span className='italic'>Severability.</span> Should any part of these Terms be held invalid, illegal, void or unenforceable, 
            that portion will deemed severed from these Terms and will not affect the validity or enforceability 
            of the remaining provisions of these Terms.
          </p>

          <p className='mb-4'>
            (vi)<span className='italic'>No Waivers.</span> Our failure or delay to exercise or enforce any right or provision of these Terms 
            will not constitute or be deemed a waiver of future exercise or enforcement of such right or 
            provision. The waiver of any right or provision of these Terms will be effective only if in writing 
            and signed for and on behalf of us by a duly authorized representative.
          </p>

          <p className='mb-4'>
            (vii)<span className='italic'>Governing Law.</span> All matters arising out of or relating to these Terms will be governed by and 
            construed in accordance with the laws of the State of New York and the federal laws of the United 
            States of America applicable therein without giving effect to any choice or conflict of law 
            provision or rule.
          </p>

          <p className='mb-4'>
            (viii)<span className='italic'>Venue.</span> Subject to Section 16 of these Terms, any legal action or proceeding arising under 
            these Terms will be brought exclusively in the state and federal courts located in New York 
            County, New York, and we and you irrevocably consent and attorn to the personal jurisdiction and 
            venue there.
          </p>

          <p className='mb-4'>
            (ix)<span className='italic'>Notices.</span> We may provide you with any notices (including, without limitation those regarding 
            changes to these Terms) by email or postings on the PROTOCOL. By providing us with your email 
            address, you consent to our using the email address to send you any notices. Notices sent by email 
            will be effective when we send the email, and notices we provide by posting will be effective upon 
            posting. It is your responsibility to keep your email address current. 
          </p>

          <p className='mb-4'>
            (x)<span className='italic'>Assignment.</span> You may not assign any of your rights or obligations under these Terms, whether 
            by operation of law or otherwise, without our prior written consent. We may assign our rights and 
            obligations under these Terms in our sole discretion to an affiliate, or in connection with an 
            acquisition, sale or merger.
          </p>
          </>
      </div>
      <Footer/>
    </Container>
    );
  }
  
  export default TermsOfService;