import { Container, Header, Main, Footer, Cards } from 'components';

const PrivacyPolicy = () => {

  const collectedInfo = [
    {
      value: <span><b>Contact and account information,</b> such as your first and last name, email address, phone number, date of birth, username and password.</span>
    },
    {
      value: <span><b>Payment and transaction data, </b>needed to complete your orders on or through the Service (including name, credit card, back account, crypto wallet or other payment information, and billing information), and your purchase history. Your payment information is processed by our payment service provider, Circle. The information you provide to Circle in connection with your payment and purchases is handled in accordance with Circle’s <a href='https://www.circle.com/en/legal/circle-pay-user-agreement-us' target='_blank' className='underline text-blue-400'>User Agreement</a> and <a href='https://www.circle.com/en/legal/privacy-policy' target='_blank' className='underline text-blue-400'>Privacy Policy.</a></span>
    },
    {
      value: <span><b>Feedback or correspondence, </b>such as information you provide when you contact us with questions, feedback, product reviews, or otherwise correspond with us online.</span>
    },  
    {
      value: <span><b>Usage information, </b>such as information about how you use the Services and interact with us, including information you provide when you use any interactive features of the Services.</span>
    },
    {
      value: <span><b>Marketing information, </b>such as your preferences for receiving communications about our activities, events, and publications, and details about how you engage with our communications.</span>
    }, 
    {
      value: <span><b>Identity verification information </b>where required to complete transactions or discharge know-your customer obligations, such as your name, date of birth, address, social media information, and driver’s license or passport information. Your identity verification information is processed by our service provider, Trulioo. The information you provide in connection with your identity verification is handled in accordance with our agreement with Trulioo.</span>
    },
    {
      value: <span><b>Other information </b>that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.</span>
    }
  ]

  const obtainedInfo = [
    {
      value: 
      <span>
        <b>Social media information.</b> We may maintain pages on social media platforms, such as Facebook, LinkedIn, Instagram, and other third-party platforms. When you visit or interact with our pages on those platforms, the platform provider’s privacy policy will apply to your interactions and their 
          collection, use and processing of your personal information. You or the platforms may provide us 
          with information through the platform, and we will treat such information in accordance with this 
          Privacy Policy.
      </span>
    },
    {
      value: 
      <span>
        <b>Other sources. </b>
        We may obtain your personal information from other third parties, such as 
        marketing partners, publicly-available sources, and data providers.
      </span>
    }
  ]
  
  const automaticData = [
    {
      value: 
      <span>
        <b>Device data, </b>
        such as your computer’s or mobile device’s operating system type and version, 
        manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device 
        type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising 
        purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 
        4G), and general location information such as city, state or geographic area.
      </span>
    },
    {
      value: 
      <span>
        <b>Online activity data, </b>
        such as pages or screens you viewed, how long you spent on a page or 
        screen, browsing history, navigation paths between pages or screens, information about your 
        activity on a page or screen, access times, and duration of access, and whether you have opened 
        our marketing emails or clicked links within them.
      </span>
    }
  ]

  const automaticDataTools = [
    {
      value: 
      <span>
        <b>Cookies, </b>
        which are text files that websites store on a visitor‘s device to uniquely identify the 
        visitor’s browser or to store information or settings in the browser for the purpose of helping 
        you navigate between pages efficiently, remembering your preferences, enabling functionality, 
        helping us understand user activity and patterns, and facilitating online advertising.
      </span>
    },
    {
      value: 
      <span>
        <b>Local storage technologies, </b>
        like HTML5, that provide cookie-equivalent functionality but can 
        store larger amounts of data, including on your device outside of your browser in connection 
        with specific applications.
      </span>
    },
    {
      value: 
      <span>
        <b>Web beacons, </b>
        also known as pixel tags or clear GIFs, which are used to demonstrate that a 
        webpage or email was accessed or opened, or that certain content was viewed or clicked.
      </span>
    }
  ]

  const serviceOperation = [
    {value: 'Provide, operate, maintain, secure and improve our Services;'},
    {value: 'Verify your identity and eligibility to participate in our Services;'},
    {value: 'Fulfill a transaction initiated by you;'},
    {value: 'Communicate with you about our Services, including by sending you announcements, updates, security alerts, and support and administrative messages;'},
    {value: 'Understand your needs and interests, and personalize your experience with our Services and our communications; and'},
    {value: 'Respond to your requests, questions and feedback.'}
  ]

  const marketingInfo = [
    {
      value: <span><b>Direct marketing. </b>We or our marketing partners may from time-to-time send you direct marketing 
      communications as permitted by law, including, but not limited to, notifying you of special 
      promotions and offers via email. You may opt out of our marketing communications as described in 
      the <a href='#optOutMarketing' className='underline text-blue-400'>“Opt out of marketing communications”</a> section below.</span>
    },
    {
      value: 
      <span><b>Interest-based advertising. </b>
        We engage our marketing partners, including third-party advertising 
        companies and social media companies, to display ads around the web. These companies may use 
        cookies and similar technologies to collect information about your interaction (including the data 
        described in the <a href='#automaticData' className='underline text-blue-400'>“Automatic data collection”</a> section above) over time across our Services, our 
        communications and other online services, and use that information to serve online ads that they 
        think will interest you. This is called interest-based advertising. We may also share information 
        about our users with these companies to facilitate interest-based advertising to those or similar 
        users on other online platforms. You can learn more about your choices for limiting interest-based 
        advertising in the <a href='#optOutOnlineTracking' className='underline text-blue-400'>“Online tracking opt-out”</a> section below.
      </span>
    }
  ]

  const sharingPersonalInfo = [
    {
      value: 'Affiliates. ',
      desc: 'We may share your personal information with our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.'
    },
    {
      value: 'Service providers. ',
      desc: 'We may share your personal information with third party companies and individuals that provide services on our behalf or help us operate our Services (such as customer support, hosting, analytics, email delivery, marketing, and database management services).'
    },
    {
      value: 'Marketing partners. ',
      desc: 'We may share your personal information with third party marketing companies, including for the interest-based advertising purposes described above. '
    },
    {
      value: 'Professional advisors. ',
      desc: 'We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.'
    },
    {
      value: 'For compliance, fraud prevention and safety. ',
      desc: 'We may share your personal information for the compliance, fraud prevention and safety purposes described above.'
    },
    {
      value: 'Business transfers. ',
      desc: 'We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution. In such a case, we will make reasonable efforts to require the recipient to honor this Privacy Policy.'
    }
  ]

  const choices = [
    {
      value: 'Access or update your information. ',
      desc: 'You may review and update certain personal information in your account profile by logging into your account.'
    },
    {
      id: 'optOutMarketing',
      value: 'Opt out of marketing communications. ',
      desc: 'You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email. You may continue to receive service related and other non-marketing emails.'
    },
    {
      id: 'optOutOnlineTracking',
      value: 'Online tracking opt-out. ',
      desc: 'There are a number of ways to opt out of having your online activity and device data collected through our Services, which we have summarized below: '
    },
  ]

  const onlineTracking = [
    {
      value: 
      <span><b>Blocking cookies in your browser. </b>
         Most browsers let you remove or reject cookies, including 
          cookies used for interest-based advertising. To do this, follow the instructions in your browser 
          settings. Many browsers accept cookies by default until you change your settings. For more 
          information about cookies, including how to see what cookies have been set on your device and 
          how to manage and delete them, visit <a href='www.allaboutcookies.org' target='_blank' className='underline text-blue-400'>www.allaboutcookies.org.</a>
      </span>
    },
    {
      value: 
      <span><b>Blocking advertising ID use in your mobile settings. </b>
          Your mobile device settings may provide 
          functionality to limit use of the advertising ID associated with your mobile device for interest-based 
          advertising purposes.
      </span>
    },
    {
      value: 
      <span><b>Using privacy plug-ins or browsers. </b>
          You can block our Services from setting cookies used for 
          interest-based ads by using a browser with privacy features, like Brave, or installing browser plugins 
          like 
          <a href='https://www.eff.org/privacybadger' target='_blank' className='underline text-blue-400'>Privacy Badger</a>, 
          <a href='https://duckduckgo.com/' target='_blank' className='underline text-blue-400'>DuckDuckGo</a>, 
          <a href='https://www.ghostery.com/' target='_blank' className='underline text-blue-400'>Ghostery</a> or 
          <a href='https://ublock.org/' target='_blank' className='underline text-blue-400'>uBlock Origin</a>, 
          and configuring them to block third party cookies/trackers.
      </span>
    },
    {
      value: 
      <span><b>Platform opt-outs. </b>
          The following advertising partners offer opt-out features that let you opt out of 
          use of your information for interest-based advertising: 
          <ul className='mt-2'>
            <li style={{listStyleType: 'circle', marginLeft: '2.5rem'}}>Google: <a href='https://adssettings.google.com' target='_blank' className='underline text-blue-400'>https://adssettings.google.com</a></li>
            <li style={{listStyleType: 'circle', marginLeft: '2.5rem'}}>Facebook: <a href='http://www.facebook.com/about/ads' target='_blank' className='underline text-blue-400'>http://www.facebook.com/about/ads </a></li>
            <li style={{listStyleType: 'circle', marginLeft: '2.5rem'}}>Twitter: <a href='https://twitter.com/settings/account/personalization?lang=en' target='_blank' className='underline text-blue-400'>https://twitter.com/settings/account/personalization?lang=en</a></li>
          </ul>
      </span>
    },
    {
      value: 
      <span><b>Advertising industry opt-out tools. </b>
          You can also use these opt-out options to limit use of your 
          information for interest-based advertising by participating companies:
          <ul className='mt-2'>
            <li style={{listStyleType: 'circle', marginLeft: '2.5rem'}}>Digital Advertising Alliance: <a href='http://optout.aboutads.info' target='_blank' className='text-underline text-blue-400'>http://optout.aboutads.info</a></li>
            <li style={{listStyleType: 'circle', marginLeft: '2.5rem'}}>Network Advertising Initiative: <a href='http://optout.networkadvertising.org/?c=1' target='_blank' className='text-underline text-blue-400'>http://optout.networkadvertising.org/?c=1</a></li>
          </ul>
      </span>
    }
  ]

  const listItems = (arr: any) => arr.map(item => {
      return(
        <li style={{listStyleType: 'disc', margin: '1rem 2rem'}}>{item.value}</li>
      ) 
  });

  const listItemHeadings = (arr: any) => arr.map(item => {
    return(
      <p className='mb-4' id={item.id}>
        <span className='text-l mb-4 font-bold'>
          {item.value}
        </span> {item.desc}
      </p>
    )
  })
  
  return (
    <Container>
      <Header/>
    <div className="privacy-policy p-1 md:p-24">
      <h1 className='text-4xl text-center mb-8'>Privacy Policy</h1>
      <p className='mb-8'>
        This Privacy Policy describes the privacy practices of Sindric Solutions, LLC (“Sindric”) and the individuals, 
        companies, and organizations we partner with (together with Sindric, “we”, “us”, or “our”) to provide the 
        services available through our website (sindric.io) and any other sites or services (e.g. xdfi.io), and 
        associated sub-domains, that link to this Privacy Policy (collectively, the “Service” or “Services”). 
      </p>
      <h3 className='text-xl mb-4 font-bold'>Personal information we collect</h3>
      <h4 className='text-l mb-4 font-bold'>Information you provide to us: </h4>
      <ul>
        {listItems(collectedInfo)}
      </ul>
      <h4 className='text-l mb-4 font-bold'>Information we obtain from third parties: </h4>
      <ul>
        {listItems(obtainedInfo)}
      </ul>
      <p>
        <span className='text-l mb-4 font-bold' id='automaticData'>
          Automatic data collection. 
        </span> We, our marketing partners, and our service providers may automatically log 
          information about you, your computer or mobile device, and your interaction over time with our 
          Services, our communications and other online services, such as:
      </p>
      <ul>
        {listItems(automaticData)}
      </ul>
      <h4 className='text-l mb-4 font-bold'>We use the following tools for automatic data collection: </h4>
      <ul>
        {listItems(automaticDataTools)}
      </ul>
      <h3 className='text-xl mb-4 font-bold'>How we use your personal information</h3>
      <h4 className='text-l mb-4 font-bold'>To operate our Services: </h4>
      <ul>
        {listItems(serviceOperation)}
      </ul>
      <p className='mb-4'>
        <span className='text-l mb-4 font-bold'>
          For research and development. 
        </span> We may use your personal information for research and development 
          purposes, including to analyze and improve our Services and our business. As part of these activities, we 
          may create aggregated, de-identified, or other anonymous data from personal information we collect. 
          We make personal information into anonymous data by removing information that makes the data 
          personally identifiable to you. We may use this anonymous data and share it with third parties for our 
          lawful business purposes, including to analyze and improve our Services and promote our business. 
      </p>
      <p>
        <span className='text-l mb-4 font-bold'>
          Marketing and advertising. 
        </span> We and our marketing partners may collect and use your personal 
          information for marketing and advertising purposes, including: 
      </p>
      <ul>
        {listItems(marketingInfo)}
      </ul>
      <p className='mb-4'>
        <span className='text-l mb-4 font-bold'>
          To comply with law. 
        </span> As we believe necessary or appropriate to comply with applicable laws, lawful 
          requests, and legal process, such as to respond to subpoenas or requests from government authorities.
      </p>
      <p className='mb-4'>
        <span className='text-l mb-4 font-bold'>
          For compliance, fraud prevention, and safety. 
        </span> To: (a) protect our, your or others’ rights, privacy, safety 
          or property (including by making and defending legal claims); (b) enforce the terms and conditions that 
          govern our Services; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, 
          unethical or illegal activity.
      </p>
      <h3 className='text-xl mb-4 font-bold'>How we share your personal information</h3>
      <ul>
        {listItemHeadings(sharingPersonalInfo)}
      </ul>
      <h3 className='text-xl mb-4 font-bold'>Your choices</h3>
      <p className='mb-4'>In this section, we describe the rights and choices available to all users. Users who are located in 
      California and Europe can find additional information about their rights below. </p>
      <ul>
        {listItemHeadings(choices)}
      </ul>
      <ul>
        {listItems(onlineTracking)}
      </ul>
      <p className='mb-4'>Note that because these opt-out mechanisms are specific to the device or browser on which they are 
        exercised, you will need to opt out on every browser and device that you use. 
      </p>
      <p className='mb-4'>
        <span className='text-l mb-4 font-bold'>
          Do Not Track.
        </span> Some Internet browsers may be configured to send “Do Not Track” signals to the online 
        services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out 
        more about “Do Not Track,” please visit <a href='http://www.allaboutdnt.com' target='_blank' className='underline text-blue-400'>http://www.allaboutdnt.com.</a>
      </p>
      <h3 className='text-xl mb-4 font-bold'>Other sites, mobile applications and services</h3>
      <p className='mb-4'>Our Services may contain links to other websites, mobile applications, and other online services 
        operated by third parties. These links are not an endorsement of, or representation that we are 
        affiliated with, any third party. In addition, our content may be included on web pages or in mobile 
        applications or online services that are not associated with us. We do not control third party websites, 
        mobile applications or online services, and we are not responsible for their actions. Other websites and 
        services follow different rules regarding the collection, use and sharing of your personal information. We 
        encourage you to read the privacy policies of the other websites and mobile applications and online 
        services you use.
      </p>
      <h3 className='text-xl mb-4 font-bold'>Security practices</h3>
      <p className='mb-4'>
        We use reasonable organizational, technical and administrative measures designed to protect against 
        unauthorized access, misuse, loss, disclosure, alteration and destruction of personal information we 
        maintain. Unfortunately, data transmission over the Internet cannot be completely secure. Therefore, 
        while we strive to protect your personal information, we cannot guarantee the security of personal 
        information.
      </p>
      <h3 className='text-xl mb-4 font-bold'>Children </h3>
      <p className='mb-4'>
        Our Services are not intended for use by children under 13 years of age. If we learn that we have 
        collected personal information through the Services from a child under 13 without the consent of the 
        child’s parent or guardian as required by law, we will delete it. 
      </p>
      <h3 className='text-xl mb-4 font-bold'>International users</h3>
      <p className='mb-4'>
        By using our Services, you understand and acknowledge that your personal information will be 
        transferred from your location to our facilities and servers in the United States. 
      </p>
      <h3 className='text-xl mb-4 font-bold'>Changes to this Privacy Policy</h3>
      <p className='mb-4'>
        We reserve the right to modify this Privacy Policy at any time. If we make material changes to this 
        Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on our 
        Services. We may also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or 
        another manner through our Services.
      </p>
      <p className='mb-4'>Any modifications to this Privacy Policy will be effective upon our posting the policy and/or upon 
        implementation of the new changes on our Services (or as otherwise indicated at the time of posting).</p>
      <h3 className='text-xl mb-4 font-bold'>How to contact us</h3>
      <p className='mb-4'>
        Please direct any questions or comments about this Policy or privacy practices to <a className='underline text-blue-400' href='mailto:hello@sindric.io'>hello@sindric.io</a> or <a className='underline text-blue-400' href='mailto:support@xdfi.io'>support@xdfi.io.</a> You may also write to us via postal mail at: 
      </p>
      <p>Sindric Solutions, LLC </p>
      <p>8 Phillip Dr </p>
      <p>Edison, NJ 08820 </p>
      <p>USA</p>
    </div>
    <Footer/>
    </Container>
  );
}

export default PrivacyPolicy;
