import { Container, Footer, Header } from "components"

const ElectronicRecordsDisclosure = () => {

    const bulletPoints = [
        [
            <span>a personal computer or other access device which is capable of accessing the Internet (e.g., 
                you must have a modem, cable Internet connection, or some other means of access to the 
                Internet, and you must have an active account with an Internet service provider), and which 
                can receive HTML files; 
            </span>,
            <span> a valid email address and, if you use a spam filter that blocks our re-routes emails from 
                unknown senders, you must permit messages from sindric.io and xdfi.io in your spam filter.
            </span>, 
            <span>a current version of a program that accurately reads and displays PDF files (e.g., Adobe 
                Acrobat Reader); 
            </span>,
            <span>an Internet web browser which is capable of supporting 128-bit SSL encrypted 
                communications; and 
            </span>,
            <span>Sufficient storage space to either download and save (to your hard disk drive or other storage 
                device) or print Disclosures.
            </span>
        ],
        [
            <span>To be bound by the terms of this Agreement;</span>, 
            <span>The Internet access device(s) you will use to receive Disclosures meet(s) the system 
                requirements described above; 
            </span>,
            <span>
                To receive Disclosures electronically at any email address or mobile telephone number 
                you have provided or made available to us;
            </span>,
            <span>The Disclosures that we provide electronically have the same meaning and effect as if 
                provided in paper form; and 
            </span>,
            <span>Your electronic acceptance or signature on any agreement or document has the same 
                effect as if you signed it in ink.
            </span>
        ]
    ]

    const listItems = (arr: any) => arr.map(item => {
        return(
          <li style={{listStyleType: 'disc', margin: '1rem 2rem'}}>{item}</li>
        ) 
    });

    return(
        <Container>
            <Header />
            <div className="privacy-policy p-1 md:p-24 ">
                <h1 className='text-4xl text-center mb-8'>Electronic Records Disclosure and Consent Agreement</h1>
                <p className='mb-4'>
                Please read this Electronic Records Disclosure and Consent Agreement (“Agreement”) carefully 
                and download and save or print a copy for your records. 
                </p>
                <p>
                In this Agreement, the words “we”, “us”, “our”, “Sindric”, “XDFi”, “XDFi Protocol” and “XDFi.io” 
                mean Sindric Solutions, LLC and its affiliates, agents, and service providers.
                </p>
                <p className="mb-4">
                    <span className="font-bold">Electronic Application and Related Disclosures. </span> We may be required by applicable law to 
                    give you certain important notices or disclosures in writing (“Disclosures”).  Without your consent, 
                    we are not permitted to give you these disclosures electronically.  We may provide these 
                    Disclosures in the form of an update to our website(s) https://www.sindric.io/ and 
                    (https://www.xdfi.io/), an associated sub-domains,; as an email, text, or communication on social 
                    media; as a notification on mobile, tablet, or wearable devices; or through other electronic means.  
                    We may also send notices to you by mail to any postal address that you have provided us.  All 
                    notices by any of these methods will be deemed received by you no later than the earlier of when 
                    received or posted, or 24 hours after sent, except for notice by postal mail, which will be deemed 
                    received by you no later than the earlier of when received or 3 business days after it is mailed
                </p>

                <p className="mb-4">
                    <span className="font-bold">Requesting Paper Copy of Disclosures.</span> At your request, we will provide a copy of your 
                    Disclosures and Agreement in paper-based media.  To request a paper copy of your Disclosures 
                    or Agreement, contact us by email at <a href='mailto:hello@sindric.io' className='underline text-blue-400'>hello@sindric.io.</a> for Sindric Solutions related disclosures 
                    and <a href='mailto:support@xdfi.io' className='underline text-blue-400'>support@xdfi.io.</a> for disclosures related to the XDFI Protocol. We will not charge you any fee 
                    for providing a paper copy. 
                </p>

                <p className="mb-4">
                    <span className="font-bold">Technology Requirements.</span>To use our services and receive an electronic copy of the 
                    Disclosures, you must have the following: 
                    <ul>
                        {listItems(bulletPoints[0])}
                    </ul>
                    We may change these requirements.  If we do, we will notify you promptly of any material 
                    changes.
                </p>

                <p className="mb-4">
                    <span className="font-bold">Updating Your Information. </span>You must keep us informed of any change to your email address 
                    or other contact information.  If you need to update your email address or other contact 
                    information, you may do so by contacting us at <a href='mailto:hello@sindric.io' className='underline text-blue-400'>hello@sindric.io.</a> for Sindric Solutions related 
                    updates and <a href='mailto:support@xdfi.io' className='underline text-blue-400'>support@xdfi.io.</a> for updates related to the XDFI Protocol and requesting the 
                    necessary updates.  We will not assume liability for non-receipt of Disclosures in the event your 
                    contact information on file is invalid; your email or Internet service provider filters the notification 
                    as "spam" or "junk mail"; there is a malfunction in your computer, mobile device, browser, Internet 
                    service, mobile connectivity and/or software; or for other reasons beyond our control. 
                </p>

                <p className="mb-4">
                    <span className="font-bold">Withdrawing Consent. </span>
                    You are free to withdraw your consent to this Agreement at any time.  
                    However, once you withdraw your consent, we will no longer be able to send you electronic copies 
                    of Disclosures, and we may close or limit your access to our services.  To withdraw your consent, 
                    please submit a request to withdraw your consent by email at <a href='mailto:hello@sindric.io' className='underline text-blue-400'>hello@sindric.io.</a>.  Any withdrawal 
                    of your consent will be effective after a reasonable period of time for processing your request.  
                    The legal effectiveness, validity, and enforceability of Disclosures that were previously provided 
                    or signed electronically will not be affected. 
                </p>

                <p className="mb-4">
                    <span className="font-bold">Acceptance of Agreement and Consent to Receive Electronic Disclosures. </span>  In order to use 
                        or access our services, you must accept this Agreement when prompted at the time you sign up 
                        for our service.  By doing so, you agree: 
                    <ul className="font-bold">
                        {listItems(bulletPoints[1])}
                    </ul>
                </p>
            </div>
            <Footer />
        </Container>
    )
}

export default ElectronicRecordsDisclosure;