import React from 'react';

import { Logo } from 'components';
import { FaSquareXTwitter } from "react-icons/fa6";


export const Banner: React.FC = () => {
  return (
    <div className='flex justify-between px-16 lg:px-24 items-center bg-green-50 hidden md:flex'>
        <div className='font-semibold lg:text-xl pr-4 text-black'>Featured in</div>
        
        <img className='cursor-pointer w-20 lg:w-24' onClick={() => window.open('https://medium.com/flarenetwork/xdfi-the-worlds-first-compliant-decentralized-futures-protocol-275c03ff6641')} src="/featuredLogos/Medium.jpeg" alt="react"/>
        <img className='cursor-pointer w-24 lg:w-32' onClick={() => window.open('https://dailycoin.com/how-xdfi-brings-institutions-to-flare-with-compliant-futures/')} src="/featuredLogos/Dailycoin.png" alt="react"/>
        <img className='cursor-pointer w-20 lg:w-24' onClick={() => window.open('https://www.bitcoininsider.org/article/241845/xdfi-launches-flare-network-revolutionizing-defi-futures-trading')} src="/featuredLogos/BitcoinInsider.png" alt="react"/>
        <img className='cursor-pointer w-20 lg:w-24' onClick={() => window.open('https://cryptonews.net/news/altcoins/28595043/')} src="/featuredLogos/cryptonews.png" alt="react"/>
        <img className='cursor-pointer w-20 lg:w-24' onClick={() => window.open('https://captainaltcoin.com/xdfi-worlds-first-compliant-decentralized-futures-protocol-to-launch-on-flare-network/')} src="/featuredLogos/captainaltcoin2.png" alt="react"/>
        <img className='cursor-pointer w-20 lg:w-24' onClick={() => window.open('https://dailyhodl.com/2024/02/21/xdfi-worlds-first-compliant-decentralized-futures-protocol-to-launch-on-flare-network/')} src="/featuredLogos/the-daily-hodl.jpg" alt="react"/>
    </div>
  )
}